import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row, Col,
} from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';

import NavRootOnly from './common/NavRootOnly';

function Home(props) {
  const { siteName, menu, address } = props;
  return (
    <div className="home">
      <Container>
        <Row>
          <Col md={4} lg={5} className="left-col">
            <div className="site-name"><h1>{RichText.render(siteName)}</h1></div>
            <div className="address">{RichText.render(address)}</div>
          </Col>
          <Col>
            <NavRootOnly menu={menu} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Home.propTypes = {
  siteName: PropTypes.arrayOf(PropTypes.object).isRequired,
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  address: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Home;
