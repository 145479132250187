import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import {
  Navbar, Nav, Container,
  Row, Col,
} from 'react-bootstrap';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';

import { APIENDPOINT, URLROOT, TITLESUFFIX } from './config/constant';
import NavFull from './common/NavFull';

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageBody: [],
    };
    this.getPage = this.getPage.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    // console.log(match);
    this.getPage(params.uid);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { params } = match;
    if (prevProps.match.params.uid !== params.uid) {
      this.getPage(params.uid);
    }
  }

  getPage(uid) {
    const apiEndpoint = APIENDPOINT;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(Prismic.Predicates.at('my.page.uid', uid)).then((response) => {
        if (response) {
          // console.log(response);
          this.setState({
            pageTitle: response.results[0].data.title,
            pageBody: response.results[0].data.body,
          });
        }
      });
    });
  }

  render() {
    const {
      siteName,
      menu,
      footer,
      match,
    } = this.props;
    const { pageTitle, pageBody } = this.state;
    return (
      <div className="page">
        <Helmet>
          <title>
            {pageTitle}
            {` - ${TITLESUFFIX}`}
          </title>
          <link rel="canonical" href={`${URLROOT}/page/${match.params.uid}`} />
        </Helmet>
        <Container>
          <Row>
            <Col lg={4} className="left-col">
              <Navbar expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Brand><Link to="/">{RichText.asText(siteName)}</Link></Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="flex-column">
                    <LinkContainer to="/">
                      <Nav.Link className="site-name">
                        {RichText.render(siteName)}
                      </Nav.Link>
                    </LinkContainer>
                    <NavFull menu={menu} />
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
            <Col>
              <div className="page-body">
                <h1>{pageTitle}</h1>
                {RichText.render(pageBody)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="footer">
                {RichText.render(footer)}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Page.propTypes = {
  siteName: PropTypes.arrayOf(PropTypes.object).isRequired,
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  footer: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
    }),
  }),
};

Page.defaultProps = {
  match: {
    params: {
      uid: '',
    },
  },
};

export default Page;
