import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';


function NavRootOnly(props) {
  const { menu } = props;
  return (
    <Nav className="flex-column">
      {menu.map((nav, j) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={j}>
          {nav.primary.url !== null
            ? (
              <LinkContainer to={nav.primary.url}>
                <Nav.Link>{nav.primary.label}</Nav.Link>
              </LinkContainer>
            ) : (
              <LinkContainer to={`/${nav.primary.page.type}/${nav.primary.page.uid}`}>
                <Nav.Link>{nav.primary.label}</Nav.Link>
              </LinkContainer>
            )
          }
        </React.Fragment>
      ))}
    </Nav>
  );
}

NavRootOnly.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NavRootOnly;
