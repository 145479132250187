import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';

function NavFull(props) {
  const {
    menu,
  } = props;
  return (
    <>
      {menu.map((nav, j) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={j} className="rootnav">
          {nav.primary.url !== null
            ? (
              <LinkContainer to={nav.primary.url}>
                <Nav.Link className="level1">{nav.primary.label}</Nav.Link>
              </LinkContainer>
            ) : (
              <LinkContainer to={`/${nav.primary.page.type}/${nav.primary.page.uid}`}>
                <Nav.Link className="level1">{nav.primary.label}</Nav.Link>
              </LinkContainer>
            )
          }


          <div className="subnav">
            {nav.items.map((subnav, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <LinkContainer key={i} to={`/${subnav['sub-page'].type}/${subnav['sub-page'].uid}`}>
                <Nav.Link className="level2">{subnav['sub-label']}</Nav.Link>
              </LinkContainer>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

NavFull.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default NavFull;
