import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Prismic from 'prismic-javascript';

import './css/main.css';
import { APIENDPOINT, URLROOT, TITLESUFFIX } from './config/constant';

import Home from './Home';
import Page from './Page';
import Contact from './Contact';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      siteName: [],
      address: [],
      footer: [],
      background: {
        url: '',
      },
    };
  }

  componentDidMount() {
    const apiEndpoint = APIENDPOINT;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(Prismic.Predicates.at('document.type', 'menu')).then((response) => {
        if (response) {
          this.setState({
            menu: response.results[0].data.body,
            siteName: response.results[0].data.site_name,
            address: response.results[0].data.address,
            footer: response.results[0].data.footer,
            background: response.results[0].data.background,
          });
          // console.log(response.results[0].data.body);
        }
      });
    });
  }

  render() {
    const { background } = this.state;
    // eslint-disable-next-line no-undef
    document.body.style.backgroundImage = `url(${background.url})`;
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{TITLESUFFIX}</title>
          <link rel="canonical" href={`${URLROOT}/`} />
        </Helmet>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Home
                  {...this.state}
                />
              )}
            />
            <Route
              exact
              path="/page/:uid"
              component={routeProps => (
                <Page
                  {...this.state}
                  {...routeProps}
                />
              )}
            />
            <Route
              exact
              path="/contact"
              component={routeProps => (
                <Contact
                  {...this.state}
                  {...routeProps}
                />
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
