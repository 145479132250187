import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import {
  Navbar, Nav, Container,
  Row, Col,
} from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';

import NavFull from './common/NavFull';
import { URLROOT, TITLESUFFIX } from './config/constant';

function Contact(props) {
  const {
    siteName,
    menu,
  } = props;
  return (
    <div className="contact">
      <Container>
        <Row>
          <Col lg={4} className="left-col">
            <Navbar expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Brand><Link to="/">{RichText.asText(siteName)}</Link></Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="flex-column">
                  <LinkContainer to="/">
                    <Nav.Link className="site-name">
                      {RichText.render(siteName)}
                    </Nav.Link>
                  </LinkContainer>
                  <NavFull menu={menu} />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col>
            <div className="page-body">
              <Helmet>
                <title>
                  Contact Us
                  {` - ${TITLESUFFIX}`}
                </title>
                <link rel="canonical" href={`${URLROOT}/contact`} />
              </Helmet>
              <h1>Contact Us</h1>
              <form action="https://usebasin.com/f/1b281382a211" method="POST">
                <label htmlFor="email-address">
                  Email Address
                  <input type="email" id="email" name="email" required />
                </label>
                <label htmlFor="name">
                  Name
                  <input id="name" name="name" required />
                </label>
                <label htmlFor="message">
                  Message
                  <textarea id="message" name="message" required />
                </label>
                <input type="hidden" name="message2" id="message2" />
                <button type="submit">Submit</button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Contact.propTypes = {
  siteName: PropTypes.arrayOf(PropTypes.object).isRequired,
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default Contact;
